import React from "react";
import { Grid, Typography } from "@mui/material";

function Login() {
  return (
    <>
      <Grid container xs={12} py={5} sx={{ justifyContent: "center" }}>
        <Typography fontSize={38}>The Nearlyweds</Typography>
      </Grid>
      <Grid container px={5} sx={{ alignItems: "center" }}>
        <Grid container xs={12} md={6} padding={6} justifyContent="center">
          <img
            width={"60%"}
            src={require("../images/engagement2.JPG")}
            alt="engagement2"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography fontSize={26}>
            Ana and Ella met at orientation at George Fox University. The two
            became fast friends when Ana spent most of first semester hanging
            out in Ella's dorm in her free time. Ana insisted on celebrating
            Ella's half birthday with half-cookies and hot chocolate in December
            of their freshman year.
          </Typography>
        </Grid>
      </Grid>

      <Grid container px={5} sx={{ alignItems: "center" }}>
        <Grid container xs={12} md={6} padding={6} justifyContent="center">
          <img
            width={"60%"}
            src={require("../images/6months.jpg")}
            alt="6months"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography fontSize={26}>
            During junior year Ana moved into Ella's house, and they both
            quickly caught feelings. They started dating on January 31, 2021.
          </Typography>
        </Grid>
      </Grid>

      <Grid container px={5} sx={{ alignItems: "center" }}>
        <Grid container xs={12} md={6} padding={6} justifyContent="center">
          <img
            width={"60%"}
            src={require("../images/graduation.jpeg")}
            alt="graduation"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography fontSize={26}>
            When they graduated in May of 2022, they moved into their first
            apartment together!
          </Typography>
        </Grid>
      </Grid>

      <Grid container px={5} sx={{ alignItems: "center" }}>
        <Grid container xs={12} md={6} padding={6} justifyContent="center">
          <img
            width={"60%"}
            src={require("../images/engagement1.JPG")}
            alt="engagement1"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography fontSize={26}>
            Ana proposed on a rainy day in Ashland in September of 2022. Abby
            and Jonah set up lights and scattered flower petals on a beautiful
            bridge in Lithia Park. Despite being soaked from a long walk in the
            rain, Ella said yes!
          </Typography>
        </Grid>
      </Grid>

      <Grid container px={5} sx={{ alignItems: "center" }}>
        <Grid container xs={12} md={6} padding={6} justifyContent="center">
          <img
            width={"60%"}
            src={require("../images/summer.jpg")}
            alt="summer"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography fontSize={26}>
            The couple has enjoyed their long engagement, but are soooo ready to
            party with friends and family and make their marriage official! They
            are so grateful for all your love and support, and are excited to
            share their special night with you.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
