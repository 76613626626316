import "./App.css";
import Navbar from "./components/Navbar";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import RsvpPage from "./pages/Rsvp/RsvpPage";
import EventDetails from "./pages/EventDetails";

// MUI components
import { CssBaseline, ThemeProvider } from "@mui/material";
// Local imports
import { appTheme } from "./themes/theme";
import Registry from "./pages/Registry";

function App() {
  const [token, setToken] = React.useState();

  if (!token) {
    return (
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />
        <Login setToken={setToken} />
      </ThemeProvider>
    );
  }

  return (
    <Router>
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/rsvp" element={<RsvpPage />} />
          <Route path="/event-details" element={<EventDetails />} />
          <Route path="/registry" element={<Registry />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
