import React from "react";
import { Grid, Typography } from "@mui/material";

function EventDetails() {
  return (
    <Grid container textAlign="center" justifyContent="center">
      <Grid
        item
        xs={8}
        sx={{
          backgroundColor: "#5a8f56",
          padding: 5,
          marginTop: 10,
          borderRadius: 2,
        }}
      >
        <Grid item>
          <Typography fontSize={22} fontWeight="bold">
            Ceremony and Reception
          </Typography>
          <Typography fontSize={18} fontWeight="bold">
            June 30th, 2024 at 6pm
          </Typography>
        </Grid>
        <Grid item sx={{ pt: 5 }}>
          <Typography>The Rooftop Terrace At Ecotrust</Typography>
          <Typography>721 NW 9th Ave</Typography>
          <Typography>Portland, OR 97209</Typography>

          <Typography paddingTop={3}>
            The ceremony will begin at 6 PM. Please arrive at 5:30 to eat
            cherries. Dinner, drinks, and dessert will follow.
          </Typography>
          <Typography>The dress code is semi-formal.</Typography>

          <Typography paddingTop={2}>
            Parking will be available on Irving Street and NW 10th Avenue.
            Carpooling is encouraged.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EventDetails;
