import React from "react";
import { Grid, Typography } from "@mui/material";

function Registry() {
  return (
    <Grid
      container
      sx={{ pt: 10, px: 5 }}
      textAlign="center"
      justifyContent="center"
    >
      <Grid
        item
        xs={8}
        sx={{
          backgroundColor: "#5a8f56",
          paddingY: 5,
          borderRadius: 2,
        }}
      >
        <Typography fontSize={20} fontWeight="bold">
          Just kidding! We don't really have a registry.
        </Typography>
        <Grid item sx={{ px: 5 }}>
          <Typography fontSize={20} paddingTop={2}>
            Instead of traditional wedding gifts or cards, we request that each
            guest bring a copy of a favorite book to help us grow our library.
            No need to wrap it, but write a note inside!
          </Typography>
        </Grid>
        <Grid item pt={3}>
          <img
            width={"50%"}
            src={require("../images/BookcaseDrawing.jpg")}
            alt="engagement1"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Registry;
