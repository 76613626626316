import React from "react";
import { PropTypes } from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function ReplyDropdown({ guestCount, label, name, value, onChange }) {
  return (
    <>
      {guestCount === 1 && (
        <Select label={label} name={name} value={value} onChange={onChange}>
          <MenuItem value={"1"}>Yes, I will be attending</MenuItem>
          <MenuItem value={"0"}>No, I will be not attending</MenuItem>
        </Select>
      )}
      {guestCount === 2 && (
        <Select label={label} name={name} value={value} onChange={onChange}>
          <MenuItem sx={{ fontSize: 14 }} value={"2"}>
            We'll both be attending
          </MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value={"1"}>
            Only one of us will be attending
          </MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value={"0"}>
            Neither of us will be attending
          </MenuItem>
        </Select>
      )}
      {guestCount === 3 && (
        <Select label={label} name={name} value={value} onChange={onChange}>
          <MenuItem value={"3"}>We'll all be attending</MenuItem>
          <MenuItem value={"2"}>Only two of us will be attending</MenuItem>
          <MenuItem value={"1"}>Only one of us will be attending</MenuItem>
          <MenuItem value={"0"}>None of us will be attending</MenuItem>
        </Select>
      )}
    </>
  );
}

ReplyDropdown.propTypes = {
  guestCount: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

export default ReplyDropdown;
