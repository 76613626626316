import React from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import RsvpForm from "./RsvpForm";

function RsvpPage() {
  const [token, setToken] = React.useState();
  const [error, setError] = React.useState(false);
  const [guestList, setGuestList] = React.useState([]);
  const [rsvpResponses, setRsvpResponses] = React.useState([]);
  const [guest, setGuest] = React.useState("");

  const currentGuestResponse = React.useMemo(
    () => rsvpResponses.find((response) => response.names === guest.names),
    [rsvpResponses, guest]
  );

  //--------------------------------------------------
  // Load Data
  //--------------------------------------------------
  const getData = React.useCallback(async () => {
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycby6Z0gcTmp4CrBnUaR2TCo-QvXAY-hde25FPYr1cQ8vpLrJDnS8DI8julPqcCh02c2j/exec",
        {
          method: "GET",
        }
      );

      const jsonResponse = await response.json();
      setRsvpResponses(jsonResponse.rsvpResponses);
      setGuestList(jsonResponse.guestList);
    } catch (err) {
      console.error("Error!", err.message);
    }
  }, []);

  React.useEffect(() => {
    getData();
  }, [getData]);

  //--------------------------------------------------
  // Useful Variables
  //--------------------------------------------------
  const hasGuestList = React.useMemo(() => guestList.length > 0, [guestList]);

  //--------------------------------------------------
  // Submitting
  //--------------------------------------------------
  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      const fullName = data.get("fullName").toLowerCase().split(" ");

      const potentialGuest = guestList.find((guest) => {
        const guestNameToMatch = guest.names.toLowerCase();
        return (
          guestNameToMatch.includes(fullName[0]) &&
          guestNameToMatch.includes(fullName[1])
        );
      });

      if (potentialGuest) {
        setError(false);
        setGuest(potentialGuest);
        setToken(true);
      } else {
        setError(true);
      }
    },
    [guestList]
  );

  return (
    <Grid
      container
      textAlign="center"
      alignItems="center"
      flexDirection="column"
    >
      {!token && (
        <Grid item sx={{ pt: 5 }}>
          <Grid
            item
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: "#5a8f56",
              padding: 5,
              marginTop: 5,
              borderRadius: 2,
            }}
          >
            {hasGuestList ? (
              <h3>Please enter your first and last name:</h3>
            ) : (
              <h3>Just a moment ... </h3>
            )}
            <Grid item paddingTop={1}>
              <TextField
                variant="outlined"
                name="fullName"
                label="First and Last"
                id="fullName"
                autoFocus
                disabled={!hasGuestList}
                helperText={error ? "This name is not on our list" : ""}
              />
            </Grid>

            <Grid item xs={12} paddingY={5}>
              <Button
                size="medium"
                variant="contained"
                disabled={!hasGuestList}
                type="submit"
              >
                RSVP
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {token && (
        <Grid container justifyContent="center">
          <Grid item sx={{ pt: 3 }} xs={12}>
            <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
              {`Hello, ${guest.names}!`}
            </Typography>
          </Grid>

          {hasGuestList && (
            <RsvpForm guest={guest} rsvpResponse={currentGuestResponse} />
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default RsvpPage;
