import React from "react";
import { Link } from "react-router-dom";
import { Divider, Grid, Typography } from "@mui/material";

const pages = [
  {
    destination: "/",
    text: "HOME",
  },
  {
    destination: "/event-details",
    text: "DETAILS",
  },
  {
    destination: "/registry",
    text: "REGISTRY",
  },
  {
    destination: "/rsvp",
    text: "RSVP",
  },
];

function Navbar() {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        flexDirection="column"
        sx={{ backgroundColor: "#548055" }}
      >
        <Grid container justifyContent="center" paddingY={1}>
          <Typography fontWeight="bold" fontSize={26} color={"#273d2d"}>
            A + E
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          {pages.map((page) => (
            <Grid item sx={{ px: 2, paddingBottom: 2 }} key={page.destination}>
              <Link to={page.destination} style={{ textDecoration: "none" }}>
                <Typography variant="navigation" color="#bfe3bf">
                  {page.text}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider
          variant="fullWidth"
          color="black"
          sx={{ borderBottomWidth: 3 }}
        />
      </Grid>
    </>
  );
}

export default Navbar;
