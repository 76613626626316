import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    background: { default: "#87ad74" },
    navigation: { default: "#f3f5d7" },
    primary: { main: "#3c6139" },
    secondary: { main: "#2e7031" },
  },
  typography: {
    navigation: {
      fontFamily: ["Glook"],
      fontSize: 18,
      color: "black",
    },
    title: {
      fontFamily: ["Caveat"],
      fontSize: 24,
    },
    button: {
      fontFamily: ["Gloock"],
    },
    body: {
      fontFamily: ["Montserrat"],
    },
    fontFamily: ["Montserrat, Gloock"],
  },
});
