import React from "react";
import { PropTypes } from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import ReplyDropdown from "../../commmon/ReplyDropdown";

const typeStyle = {
  fontSize: 18,
  fontWeight: "bold",
};

function RsvpForm({ guest, rsvpResponse }) {
  const [currentRsvpResponse, setCurrentRsvpResponse] = React.useState(
    rsvpResponse || null
  );
  const [clickedEdit, setClickedEdit] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  //--------------------------------------------------
  // Set initial values
  //--------------------------------------------------
  const [rsvp, setRsvp] = React.useState(currentRsvpResponse?.rsvp || "");
  const [popsicle, setPopsicle] = React.useState(
    currentRsvpResponse?.popsicle || ""
  );
  const [popsicle2, setPopsicle2] = React.useState(
    currentRsvpResponse?.popsicle2 || ""
  );
  const [popsicle3, setPopsicle3] = React.useState(
    currentRsvpResponse?.popsicle3 || ""
  );
  const [rehearsal, setRehearsal] = React.useState(
    currentRsvpResponse?.rehearsal || ""
  );
  const [afterparty, setAfterparty] = React.useState(
    currentRsvpResponse?.afterparty || ""
  );
  const [bachelorette, setBachelorette] = React.useState(
    currentRsvpResponse?.bachelorette || ""
  );
  const [dietary, setDietary] = React.useState(
    currentRsvpResponse?.dietary || ""
  );
  const [comments, setComments] = React.useState(
    currentRsvpResponse?.comments || ""
  );

  //--------------------------------------------------
  // Handle changes
  //--------------------------------------------------
  const handleRsvpChange = React.useCallback(
    (event) => setRsvp(event.target.value),
    []
  );
  const handlePopsicleChange = React.useCallback(
    (event) => setPopsicle(event.target.value),
    []
  );
  const handlePopsicle2Change = React.useCallback(
    (event) => setPopsicle2(event.target.value),
    []
  );
  const handlePopsicle3Change = React.useCallback(
    (event) => setPopsicle3(event.target.value),
    []
  );
  const handleRehearsalChange = React.useCallback(
    (event) => setRehearsal(event.target.value),
    []
  );
  const handleAfterpartyChange = React.useCallback(
    (event) => setAfterparty(event.target.value),
    []
  );
  const handleBacheloretteChange = React.useCallback(
    (event) => setBachelorette(event.target.value),
    []
  );
  const handleDietaryChange = React.useCallback(
    (event) => setDietary(event.target.value),
    []
  );
  const handleCommentsChange = React.useCallback(
    (event) => setComments(event.target.value),
    []
  );

  const attendanceQuestions = React.useMemo(() => {
    const questions = [
      {
        name: "bachelorette",
        value: bachelorette,
        title: "Bachelorette Weekend",
        question: "Will you be attending our bachelorette weekend?",
        date: "May 25-27",
        onChange: handleBacheloretteChange,
      },
      {
        name: "rehearsal",
        value: rehearsal,
        title: "Rehearsal Dinner",
        question: "Will you be attending our rehearsal dinner?",
        date: "6pm on June 29",
        onChange: handleRehearsalChange,
      },
      {
        name: "afterparty",
        value: afterparty,
        title: "Afterparty",
        question: "Will you be attending our afterparty?",
        date: "10pm on June 30",
        onChange: handleAfterpartyChange,
      },
    ];

    if (!guest.bachelorette.includes("yes")) {
      questions.splice(
        questions.findIndex((question) => question.name === "bachelorette"),
        1
      );
    }

    if (!guest.rehearsal.includes("yes")) {
      questions.splice(
        questions.findIndex((question) => question.name === "rehearsal"),
        1
      );
    }

    if (!guest.afterparty.includes("yes")) {
      questions.splice(
        questions.findIndex((question) => question.name === "afterparty"),
        1
      );
    }

    return questions;
  }, [
    handleAfterpartyChange,
    afterparty,
    handleBacheloretteChange,
    bachelorette,
    handleRehearsalChange,
    rehearsal,
    guest,
  ]);

  //--------------------------------------------------
  // Submit
  //--------------------------------------------------
  const handleSubmit = React.useCallback(
    async (event) => {
      event.preventDefault();
      // Read the form data
      setSubmitting(true);
      const form = event.target;
      const formData = new FormData(form);
      formData.append("names", guest.names);

      setClickedEdit(false);

      try {
        await fetch(
          "https://script.google.com/macros/s/AKfycby6Z0gcTmp4CrBnUaR2TCo-QvXAY-hde25FPYr1cQ8vpLrJDnS8DI8julPqcCh02c2j/exec",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (err) {
        setSubmitting(false);
        console.error("Error!", err.message);
      }
      setCurrentRsvpResponse({
        afterparty,
        bachelorette,
        comments,
        dietary,
        popsicle,
        rehearsal,
        rsvp,
      });
      setSubmitting(false);
    },
    [
      guest.names,
      afterparty,
      bachelorette,
      comments,
      dietary,
      popsicle,
      rehearsal,
      rsvp,
    ]
  );

  return (
    <Grid
      item
      component="form"
      id="form"
      onSubmit={handleSubmit}
      noValidate
      xs={12}
      md={6}
      sx={{
        backgroundColor: "#5a8f56",
        paddingX: 2,
        borderRadius: 2,
        marginY: 5,
      }}
    >
      {currentRsvpResponse && !clickedEdit && (
        <Grid item py={5}>
          <h3>
            You've submitted your RSVP. Click Edit if you'd like to change your
            response
          </h3>
          <Grid item xs={12} paddingY={5}>
            <Button
              size="medium"
              variant="contained"
              onClick={() => {
                setClickedEdit(true);
              }}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      )}

      {/* RSVP form */}
      {(!currentRsvpResponse || clickedEdit) && (
        <Grid item>
          {/* RSVP */}
          <Grid item paddingY={1}>
            <Grid item>
              <Typography style={typeStyle}>
                Will you be attending our ceremony and reception?
              </Typography>
            </Grid>
            <Grid item sx={{ pb: 1 }}>
              <Typography variant="body" fontStyle="italic" fontSize={14}>
                6pm on June 30th
              </Typography>
            </Grid>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
              <InputLabel>RSVP</InputLabel>
              <ReplyDropdown
                label="Rsvp"
                name="rsvp"
                value={rsvp}
                onChange={handleRsvpChange}
                guestCount={guest.guestCount}
              />
            </FormControl>
          </Grid>
          {/* Dietary Restrictions */}
          {rsvp !== "" && rsvp !== "0" && (
            <Grid item>
              <Grid item paddingY={1}>
                <Grid item sx={{ pt: 2 }}>
                  <Typography style={typeStyle}>
                    Please list any dietary restrictions:
                  </Typography>
                </Grid>
                <TextField
                  name="dietary"
                  value={dietary}
                  onChange={handleDietaryChange}
                  sx={{ m: 1, minWidth: 240 }}
                />
              </Grid>
              {/* // TODO: Make into a multi-line input*/}
              <Grid item paddingY={1}>
                <Grid item sx={{ pt: 2 }}>
                  <Typography style={typeStyle}>
                    Select your preferred popsicle flavor:
                  </Typography>
                </Grid>
                <Grid item sx={{ pb: 1 }}>
                  <Typography
                    variant="body"
                    fontStyle="italic"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    all options are vegan
                  </Typography>
                </Grid>
                <FormControl sx={{ m: 1, minWidth: 240 }}>
                  <InputLabel>Popsicle Flavor</InputLabel>
                  <Select
                    label="Popsicle Flavor"
                    name="popsicle"
                    value={popsicle}
                    onChange={handlePopsicleChange}
                  >
                    <MenuItem value={"Cherry Old Fashioned"}>
                      Cherry Old Fashioned
                    </MenuItem>
                    <MenuItem value={"Raspberry Sangria"}>
                      Raspberry Sangria
                    </MenuItem>
                    <MenuItem value={"Oregon Berry Swirl"}>
                      {"Oregon Berry Swirl (N/A)"}
                    </MenuItem>
                    <MenuItem value={"No popsicle"}>
                      I don't want a popsicle
                    </MenuItem>
                  </Select>
                </FormControl>

                {(rsvp === "2" || rsvp === "3") && (
                  <Grid item sx={{ pt: 1 }}>
                    <FormControl sx={{ m: 1, minWidth: 240 }}>
                      <InputLabel>Popsicle Flavor</InputLabel>
                      <Select
                        label="Popsicle Flavor"
                        name="popsicle2"
                        value={popsicle2}
                        onChange={handlePopsicle2Change}
                      >
                        <MenuItem value={"Cherry Old Fashioned"}>
                          Cherry Old Fashioned
                        </MenuItem>
                        <MenuItem value={"Raspberry Sangria"}>
                          Raspberry Sangria
                        </MenuItem>
                        <MenuItem value={"Oregon Berry Swirl"}>
                          {"Oregon Berry Swirl (N/A)"}
                        </MenuItem>
                        <MenuItem value={"No popsicle"}>
                          I don't want a popsicle
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {rsvp === "3" && (
                  <Grid item sx={{ pt: 1 }}>
                    <FormControl sx={{ m: 1, minWidth: 240 }}>
                      <InputLabel>Popsicle Flavor</InputLabel>
                      <Select
                        label="Popsicle Flavor"
                        name="popsicle3"
                        value={popsicle3}
                        onChange={handlePopsicle3Change}
                      >
                        <MenuItem value={"Cherry Old Fashioned"}>
                          Cherry Old Fashioned
                        </MenuItem>
                        <MenuItem value={"Raspberry Sangria"}>
                          Raspberry Sangria
                        </MenuItem>
                        <MenuItem value={"Oregon Berry Swirl"}>
                          {"Oregon Berry Swirl (N/A)"}
                        </MenuItem>
                        <MenuItem value={"No popsicle"}>
                          I don't want a popsicle
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {/* Other Attendance Questions */}
          {attendanceQuestions.map((question) => (
            <Grid paddingY={1} key={question.name}>
              <Grid item sx={{ pt: 2 }}>
                <Typography style={typeStyle}>{question.question}</Typography>
              </Grid>
              <Grid item sx={{ pb: 1 }}>
                <Typography
                  variant="body"
                  fontStyle="italic"
                  style={{
                    fontSize: 14,
                  }}
                >
                  {question.date}
                </Typography>
              </Grid>
              <FormControl sx={{ m: 1, minWidth: 240 }}>
                <InputLabel>{question.title}</InputLabel>
                <ReplyDropdown
                  label={question.title}
                  name={question.name}
                  value={question.value}
                  onChange={question.onChange}
                  guestCount={guest.guestCount}
                />
              </FormControl>
            </Grid>
          ))}
          {/* // TODO Make into a multi-line input */}
          <Grid item paddingY={1}>
            <Grid item sx={{ pt: 2 }}>
              <Typography style={typeStyle}>
                Is there anything you'd like to add?
              </Typography>
            </Grid>
            <TextField
              name="comments"
              value={comments}
              onChange={handleCommentsChange}
              sx={{ m: 1, minWidth: 240 }}
            />
          </Grid>
          <Grid item sx={{ padding: 2 }}>
            <Button
              disabled={submitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

RsvpForm.propTypes = {
  guest: PropTypes.object.isRequired,
  rsvpResponse: PropTypes.object,
};

export default RsvpForm;
