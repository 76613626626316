import React from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import { PropTypes } from "prop-types";

function Login({ setToken }) {
  const validPassword = "06.30.24";

  const [error, setError] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("password");

    if (password === validPassword) {
      setError(false);
      setToken(true);
    } else {
      setError(true);
    }
  };

  return (
    <Grid
      container
      textAlign="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography fontSize={34} py={5}>
        Welcome to Ana and Ella's Wedding Website!
      </Typography>
      <Grid
        item
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{
          backgroundColor: "#5a8f56",
          padding: 3,
          borderRadius: 2,
        }}
      >
        <h3>Please enter the website password:</h3>
        <Grid item paddingTop={1}>
          <TextField
            variant="outlined"
            name="password"
            label="Password"
            id="password"
            autoFocus
            helperText={error ? "Incorrect password" : ""}
          />
        </Grid>
        <Grid item paddingY={5}>
          <Button size="medium" variant="contained" type="submit">
            Sign In
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
